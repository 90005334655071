import React, {useContext, useState, useRef, useEffect} from 'react'
import { navigate } from 'gatsby'
import MUIDataTable from 'mui-datatables'
import {
  Typography,
  Collapse,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,

  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Snackbar,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Layout from '../../layouts'
import Hider from '../../components/utils/Hider'
import AccessCheck from '../../components/attendance/accessCheck'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import  { registerLocale } from 'react-datepicker'
import { ja } from 'date-fns/locale'
import 'react-datepicker/dist/react-datepicker.css'
import {CmDataPicker} from "../../components/cfp/cmDataPicker";
registerLocale('ja', ja)

const Share = ({ location }) => {
  const { gCompanyDevice, gCompanyEmployee} = useContext(GlobalStateContext)
  const [open, setOpen] = useState(true)
  const [openSnack, setOpenSnack] = useState(false)
  const [openUser, setOpenUser] = useState(true)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [showShare, setShowShare] = useState(false)
  const [startDate, setStartDate] = useState(+new Date())
  const [endDate, setEndDate] = useState(+new Date() + 1800000)
  const [guestKeyTime, setGuestKeyTime] = useState('常時利用')

  const [level, setLevel] = useState(2)
  const [disableSend, setDisableSend] = useState(true)
  const snackBtn = useRef(null)
  const handleClick = () => {
    setOpenSnack(true)
  }
    useEffect(()=>{

      return ()=>{
        gCompanyDevice.setIsOpenDialog(false)

      }
    },[])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }
  const callTime = (startTime, endTime) => {
    setStartDate(startTime)
    setEndDate(endTime)



 /*   console.log("calltime222",Math.floor(startTime / 1000),Math.floor(endTime / 1000))
    setTime({ startTime, endTime })*/
  }
  const action = (
    <>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={handleClose}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  )

  const checkShare = (selectedRows) => {
    const list = gCompanyEmployee.employees.Items
    let indexArr = []
    selectedRows.data.map((item) => {
      delete item.index
      indexArr.push(...Object.values(item))
    })
    const checkShare = list.filter((item, index) => {
      return indexArr.includes(index)
    })
    setSelectedUsers(checkShare)
  }

  return (
    <Layout location={location}>
      <AccessCheck access={'デバイス'}>
        <Button onClick={handleClick} ref={snackBtn} sx={{ display: 'none' }}>
          snackBar
        </Button>
        <Box sx={{ mb: '15px', bgcolor: 'white', borderRadius: '5px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '10px 15px',
            }}
          >
            <Typography variant='h2'>選択済デバイス</Typography>
            <IconButton
              onClick={() => {
                setOpen(!open)
              }}
            >
              <Hider show={open}>
                <KeyboardArrowUpIcon />
                <KeyboardArrowDownIcon />
              </Hider>
            </IconButton>
          </Box>
          <Collapse in={open}>
            <MUIDataTable
              title={null}
              data={gCompanyDevice.selectedDevice}
              columns={[
                { name: 'deviceName', label: 'デバイス名' },
                { name: 'deviceUUID', label: 'UUID' },
                {
                  name: 'keyLevel',
                  label: '合鍵権限',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <>
                          {value === 0
                            ? 'オーナー鍵'
                            : value === 1
                            ? 'マネージャー鍵'
                            : 'ゲスト鍵'}
                        </>
                      )
                    },
                  },
                },
              ]}
              options={{
                setTableProps: () => {
                  return {
                    fullWidth: true,
                    size: 'small',
                  }
                },
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                customToolbar: null,
                pagination: false,
                selectableRows: 'none',
                elevation: 0,
                textLabels: {
                  body: {
                    noMatch: '検索結果は０件です',
                    toolTip: 'ソート',
                    columnHeaderTooltip: (column) => `ソート ${column.label}`,
                  },
                },
              }}
            />
          </Collapse>
        </Box>
        <Hider show={true}>
          <Card sx={{ mb: '15px' }}>
            <CardHeader
              title={<Typography variant='h2'>合鍵の権限を選択</Typography>}
              sx={{ pb: '5px' }}
            ></CardHeader>
            <CardContent>
              <FormControl sx={{ display: 'center', alignItems: 'center' }}>
                <RadioGroup
                  row
                  value={level}
                  onChange={(e) => {
                    setLevel(e.target.value)
                  }}
                >
                  <Hider
                    // 如果選擇的裝置含有manager，則不能顯示owner選項
                    show={
                      gCompanyDevice.selectedDevice.find(
                        (element) => element.keyLevel === 1
                      ) === undefined
                    }
                  >
                    <FormControlLabel
                      value='0'
                      control={<Radio />}
                      label='オーナー鍵'
                      disabled={showShare === true}
                    />
                  </Hider>
                  <FormControlLabel
                    value='1'
                    control={<Radio />}
                    label='マネージャー鍵'
                    disabled={showShare === true}
                  />
                  <FormControlLabel
                    value='2'
                    control={<Radio />}
                    label='ゲスト鍵'
                    disabled={showShare === true}
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Hider>
        <Hider show={showShare}>
          <Box sx={{ mb: '15px', bgcolor: 'white', borderRadius: '5px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '10px 15px',
              }}
            >
              <Typography variant='h2'>選択済ユーザー</Typography>
              <IconButton
                onClick={() => {
                  setOpenUser(!openUser)
                }}
              >
                <Hider show={openUser}>
                  <KeyboardArrowUpIcon />
                  <KeyboardArrowDownIcon />
                </Hider>
              </IconButton>
            </Box>
            <Divider />
            <Collapse in={openUser}>
              <MUIDataTable
                title={null}
                columns={[
                  { name: 'employeeName', label: 'ユーザー名' },
                  { name: 'tag', label: 'ユーザー権限 ' },
                  { name: 'employeeEmail', label: 'メールアドレス' },
                ]}
                data={selectedUsers}
                options={{
                  setTableProps: () => {
                    return {
                      fullWidth: true,
                      size: 'small',
                    }
                  },
                  filter: false,
                  search: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  customToolbar: null,
                  pagination: false,
                  selectableRows: 'none',
                  elevation: 0,
                  textLabels: {
                    body: {
                      noMatch: '検索結果は０件です',
                      toolTip: 'ソート',
                      columnHeaderTooltip: (column) => `ソート ${column.label}`,
                    },
                  },
                }}
              />
            </Collapse>
          </Box>
          <MUIDataTable
            title={<Typography variant='h2'>選択済ユーザー</Typography>}
            columns={[
              { name: 'employeeName', label: 'ユーザー名' },
              { name: 'tag', label: 'ユーザー権限 ' },
              { name: 'employeeEmail', label: 'メールアドレス' },
              { name: 'department', label: '所属' },
            ]}
            data={gCompanyEmployee.employees.Items}
            options={{
              setTableProps: () => {
                return { size: 'small' }
              },
              search: false,
              print: false,
              filter: true,
              toolbar:false,
              download: false,
              viewColumns: false,
              customToolbarSelect: (selectedRows, displayData) => (
                <div style={{ marginRight: '24px' }}>
                  <Button
                    disableElevation
                    variant='contained'
                    size='small'
                    sx={{ color: 'white' }}
                    onClick={() => {
                      checkShare(selectedRows, displayData)
                      setShowShare(true)
                      setDisableSend(false)
                    }}
                  >
                    {level > 1 ? '利用時間設定' : '確認'}
                  </Button>
                </div>
              ),
              filterType: 'textField',
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: '検索結果は０件です',
                  toolTip: 'ソート',
                  columnHeaderTooltip: (column) => `ソート ${column.label}`,
                },
                pagination: {
                  next: 'Next Page',
                  previous: 'Previous Page',
                  rowsPerPage: '表示数',
                  displayRows: '/',
                },
                toolbar: {
                  search: '検索',
                  downloadCsv: 'CSV',
                  print: '印刷する',
                  viewColumns: '列を表示',
                  filterTable: 'フィルター',
                },
                viewColumns: {
                  title: '列を表示',
                  titleAria: 'Show/Hide Table Columns',
                },
                filter: {
                  all: '全て',
                  title: 'フィルター',
                  reset: 'リセット',
                },
                selectedRows: {
                  text: '選択済み',
                  delete: '削除',
                  deleteAria: 'Delete Selected Rows',
                },
              },
            }}
          />
        </Hider>
        <Hider show={showShare && level > 1}>
          <Card sx={{ overflow: 'unset' }}>
            <CardHeader
              title={<Typography variant='h2'>有効時間を設定</Typography>}
              sx={{ pb: '5px' }}
            />
            <CardContent>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  value={guestKeyTime}
                  onChange={(e) => {
                    setGuestKeyTime(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value='常時利用'
                    control={<Radio />}
                    label='常時利用'
                  />

                  <Box sx={{ display: 'flex', flexGrow: '1' }}>
                    <FormControlLabel
                      value='一時利用'
                      control={<Radio />}
                      label='一時利用'
                    />
                    <Hider show={guestKeyTime === '一時利用'}>
                      <CmDataPicker
                          callTime={callTime}
                          sx={{
                            display: "flex",
                            marginBottom: "10px",

                            alignItems: "center",
                            marginLeft: "40px",
                            flexGrow: "1",
                          }}
                      />

                    </Hider>
                  </Box>
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Hider>
        <Button
          disableElevation
          size='small'
          sx={{ m: '15px', color: 'white' }}
          variant='contained'
          disabled={
            gCompanyDevice.selectedDevice.length === 0 ||
            selectedUsers.length === 0 ||
            disableSend
          }
          onClick={async () => {

         //   console.log("shareLock1", selectedUsers)
         //   console.log("shareLock2", gCompanyDevice.selectedDevice)
          //  setDisableSend(true)
            for (let i = 0; i < selectedUsers.length; i++) {
              for (let j = 0; j < gCompanyDevice.selectedDevice.length; j++) {
                let item = {

                  ...gCompanyDevice.selectedDevice[j],
                  ...selectedUsers[i],
                  keyLevel: level,
                  startTime:
                    guestKeyTime === '一時利用'
                      ? Math.floor(startDate / 1000)
                      : '',
                  endTime:
                    guestKeyTime === '一時利用'
                      ? Math.floor(endDate / 1000)
                      : '',
                  // sharePerson: gLoginEmployee.loginInfo.employeeEmail,
                  // shareDate: Math.floor(shareDateTime / 1000),
                }
               // delete item.companyID
                // await setDisableSend(false)

               await gCompanyEmployee.shareLock(item,()=>{
                 navigate(`/employees`)
               })

             /*   await API.post('Attendance', `/employee/key`, {
                  body: item,
                })
                  .then((res) => {
                    if (res === '200') {
                      snackBtn.current.click()
                    }
                  })
                  .catch((err) => console.log(err))*/
              }
            }

          }}

        >
          合鍵発行
        </Button>
        <Snackbar
          open={openSnack}
          autoHideDuration={2000}
          onClose={handleClose}
          message='成功！'
          action={action}
          sx={{
            '& .MuiSnackbarContent-root': { backgroundColor: 'primary.main' },
            fontWeight: 'bold',
          }}
        ></Snackbar>
      </AccessCheck>
    </Layout>
  )
}

export default Share
